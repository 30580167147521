import { Col, Row } from "react-bootstrap";

import Circle from "../../generics/gribouillis/circle";
import Image from "next/legacy/image";
import Line from "../../generics/gribouillis/line";
import Script from "next/script";
import bill from "../../../assets/generics/graphical-svg/orange/bill-2.svg";

const HeroBanner = () => {
  return (
    <>
      <Row className="m-auto all flex-row-reverse herroBanner py-5">
        <Col xs={12} sm={1} md={2} lg={1} xl={2}></Col>
        <Col xs={12} sm={10} md={8} lg={5} xl={4} className="my-auto py-2">
          <h1 className="mt-4 _g">
            Faites-vous{" "}
            <Line variant={4} weight={2} margin={3} color={1} opacity={3}>
              confiance
            </Line>{" "}
            à vos&nbsp;factures ?
          </h1>
          <p className="lead mt-4 mb-0 _g">
            72% des français déclarent ne jamais se pencher sur leurs{" "}
            <Circle
              className="fw-bold"
              variant={2}
              weight={3}
              margin={2}
              color={1}
              opacity={3}
            >
              abonnements
            </Circle>
            .
          </p>
          <Row>
            <Col xs={12} lg={4} xl={5} className="h-100 m-auto pt-5">
              <div
                className="justify-content-start w-100 m-auto d-flex justify-content-center"
                id="329dc4a9-0642-4242-aa07-27c322e055d3"
              ></div>
              <Script
                src="https://brand-widgets.rr.skeepers.io/generated/eea0f5e0-6f7c-b404-c978-4ccb6f23eec8/329dc4a9-0642-4242-aa07-27c322e055d3.js"
                async
                id="jhvgbk"
              ></Script>
            </Col>
          </Row>
        </Col>
        <Col sm={1} md={2} className="d-lg-none d-none d-sm-block"></Col>
        <Col sm={1} md={2} className="d-lg-none d-none d-sm-block"></Col>
        <Col
          xs={12}
          sm={10}
          md={8}
          lg={5}
          xl={4}
          className="d-flex justify-content-center"
        >
          <div
            style={{
              width: "70%",
              height: "70%",
              minHeight: "300px",
              position: "relative",
            }}
            className="d-flex m-auto"
          >
            <Image
              className="m-auto"
              src={bill}
              alt="simple à utiliser"
              layout="fill"
              priority
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={2} lg={1} xl={2}></Col>
      </Row>
    </>
  );
};

export default HeroBanner;
