import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import Link from "next/link";

const SmallNavAnnoucement = () => {
  return (
    <Row className="w-100 m-0 p-0 bg-orange3">
      <Col xs={12} sm={12} md={12} lg={10} xl={10} className="mx-auto px-0">
        <Navbar className="py-0" bg="light">
          <Container>
            <Nav className="justify-content-center text-center d-flex mx-auto">
              <Navbar.Text>
                Suspension de notre service gratuit.
                <Link
                  legacyBehavior
                  href="/actualites/suspension-service-gratuit-0e6dbed496094d2e9895db841551eeb3"
                  passHref
                >
                  <a
                    className={`my-auto text-decoration-none px-2 text-primary fw-bold`}
                  >
                    Lire l’annonce →
                  </a>
                </Link>
              </Navbar.Text>
            </Nav>
          </Container>
        </Navbar>
      </Col>
    </Row>
  );
};

export default SmallNavAnnoucement;
