import { Col, Row } from "react-bootstrap";
import { arrayOf, object } from "prop-types";

import BannerFooter from "../components/kabru/bannerFooter";
import CarouselArticles from "../components/rakshi/carouselArticles";
import Clickable from "/components/generics/clickable";
import Draw from "../components/generics/gribouillis/draw";
import Explain from "../components/kabru/index/explain";
import HeroBanner from "../components/kabru/index/herroBanner";
import HerosHome from "../components/kabru/index/herosHome";
import KabruPage from "/components/kabru/kabruPage";
import Link from "next/link";
import { NextSeo } from "next-seo";
import NotionService from "../services/notionService";
import RassureCards from "../components/kabru/index/rassureCards";
import Services from "../components/kabru/index/services";
import Testimonials from "../components/kabru/index/testimonials";
import UrlTracker from "../lib/urlTracker";
import getTrad from "../lib/getTrad";
import { useDispatch } from "react-redux";

//import Witness from "../components/kabru/index/witness";

export async function getStaticProps({ locale }) {
  const t = await getTrad(locale);
  const notionService = new NotionService();
  const posts = await notionService.getPublishedBlogPosts();
  return {
    props: {
      kabru: t.kabru,
      posts: posts.sort((a, b) => b.rank - a.rank).slice(0, 6),
    },
  };
}

const Home = ({ kabru, posts }) => {
  Home.propTypes = {
    kabru: object,
    posts: arrayOf(object),
  };
  const dispatch = useDispatch();
  dispatch({
    type: "setDataLayer",
    content: {
      event: "pageKabruLoaded",
      current_app: "kabru",
    },
  });
  return (
    <>
      <NextSeo
        title="Cherpas • Passez aux abonnements intelligents."
        description="Cherpas s'occupe de vos factures et les optimise à vie. Nos membres économisent 219 € en moyenne sur leurs factures."
        canonical={`${process.env.NEXT_PUBLIC_SITE_URL}`}
      />
      <UrlTracker />
      <KabruPage smallNav>
        <HeroBanner kabru={kabru} />
        <Services kabru={kabru} />
        <HerosHome />
        <Explain kabru={kabru} />
        {/* <Witness kabru={kabru} /> */}
        <RassureCards kabru={kabru} />
        <Testimonials kabru={kabru} />
        <BannerFooter />
        <Row className="w-100 m-0 py-4 bg-grey7">
          <Col className="mx-auto" xs={11} lg={10}>
            <div className="text-center py-4">
              <h2 className="fw-bold _g">
                Nos derniers{" "}
                <Draw variant={2} opacity={3} position={2}>
                  articles
                </Draw>
              </h2>
              <Link legacyBehavior href="/conseils" passHref prefetch={false}>
                <a className="text-primary lead">
                  <Clickable force={1}>
                    <span>Tous nos conseils&nbsp;➜</span>
                  </Clickable>
                </a>
              </Link>
            </div>
            <CarouselArticles posts={posts} />
          </Col>
        </Row>
      </KabruPage>
    </>
  );
};

export default Home;
