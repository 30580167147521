import { Card, Carousel, Col, Row } from "react-bootstrap";
import { number, object, string } from "prop-types";

import Clickable from "/components/generics/clickable";
import Draw from "../../generics/gribouillis/draw";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/legacy/image";
import Sun from "../../generics/gribouillis/sun";
import avisVerifies from "../../../assets/kabru/avis-verifies.svg";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarL } from "@fortawesome/pro-light-svg-icons";

const Testimonials = () => {
  const AvisCard1 = (props) => {
    AvisCard1.propTypes = {
      text: object,
      link: string,
      name: object,
      date: string,
      icon: object,
      stars: number,
    };
    return (
      <Card
        style={{ minHeight: "200px" }}
        className="shadow border-0 text-start h-100 mx-2"
      >
        <Card.Body>
          {props.stars === 4 && (
            <p className="mb-2">
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStarL}
              />
            </p>
          )}
          {props.stars === 5 && (
            <p className="mb-2">
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
            </p>
          )}
          {props.text}
        </Card.Body>
        <a
          href={props.link}
          className="text-decoration-none text-grey1"
          target="blank"
        >
          <Card.Footer className="d-flex border-top-0">
            <div className="my-auto ms-3 fw-bold name">
              {props.name}&nbsp;&nbsp;({props.date})
            </div>
            <div className="ms-auto my-auto">
              <Image
                alt="avis-vérifiés"
                src={avisVerifies}
                width={"60px"}
                height={30}
              />
            </div>
          </Card.Footer>
        </a>
      </Card>
    );
  };

  const AvisCard2 = (props) => {
    AvisCard2.propTypes = {
      text: object,
      link: string,
      name: object,
      date: string,
      icon: object,
      stars: number,
    };
    return (
      <Card
        style={{ minHeight: "200px" }}
        className="shadow border-0 text-start h-100 mx-2"
      >
        <Card.Body>
          {props.stars === 4 && (
            <p className="mb-2">
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStarL}
              />
            </p>
          )}
          {props.stars === 5 && (
            <p className="mb-2">
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
              <FontAwesomeIcon
                size="1x"
                style={{
                  color: "#ff5f3e",
                  maxHeight: "30px",
                  maxWidth: "30px",
                }}
                icon={faStar}
              />
            </p>
          )}
          {props.text}
        </Card.Body>
        <a
          href={props.link}
          className="text-decoration-none text-grey1 bottomLink"
          target="blank"
        >
          <Card.Footer className="d-flex border-top-0">
            <div className="my-auto ms-3 fw-bold name">
              {props.name}&nbsp;&nbsp;({props.date})
            </div>
            <div className="ms-auto my-auto">
              <Image
                alt="avis-vérifiés"
                src={avisVerifies}
                width={"60px"}
                height={30}
              />
            </div>
          </Card.Footer>
        </a>
      </Card>
    );
  };

  return (
    <Row className="carouselStep background py-5 mx-auto all bg-grey7">
      <Col xs={12} sm={1} md={1} lg={1} xl={2}></Col>
      <Col
        xs={12}
        sm={10}
        md={10}
        lg={10}
        xl={8}
        className="my-auto text-center"
      >
        <h2 className="mt-3 fw-bold text-primary _g">
          <Sun position={1} variant={4} opacity={3} weight={3}>
            Ce
          </Sun>{" "}
          que disent nos{" "}
          <Draw position={2} variant={3} opacity={3}>
            membres
          </Draw>
        </h2>

        <a
          href="https://www.avis-verifies.com/avis-clients/cherpas.com"
          className="lead fw-bold text-blue3"
          target="blank"
          size="1x"
          style={{
            maxHeight: "30px",
            maxWidth: "30px",
          }}
        >
          <Clickable force={2}>Partagez votre avis&nbsp;→</Clickable>
        </a>

        <Carousel
          controls={false}
          indicators={true}
          fade
          className="py-5"
          variant="dark"
          interval={10000}
          wrap={false}
        >
          <Carousel.Item>
            <Card className="cardDouble bg-grey7 mx-auto border border-0">
              <Row className="px-1 py-2 m-1">
                <Col xs={12} lg={6} className="m-0 p-0 my-2 bg-grey7">
                  <AvisCard1
                    text="J'ai utilisé plusieurs fois cherpas.com pour changer d'abonnement Internet. Tout s'est passé comme sur des roulettes!"
                    name="XAVIER P."
                    date="23/06/2022"
                    link="https://www.avis-verifies.com/avis-clients/cherpas.com"
                    stars={5}
                  />
                </Col>
                <Col xs={12} lg={6} className="m-0 p-0 my-2 bg-grey7">
                  <AvisCard2
                    text="Très contente du service, que d'économies depuis que j'ai adhéré à cherpas , et mis appart au début aucunes paperasses!! je recommande +++++"
                    name="ANAIS L."
                    date="28/06/2022"
                    link="https://www.avis-verifies.com/avis-clients/cherpas.com"
                    stars={5}
                  />
                </Col>
              </Row>
            </Card>
          </Carousel.Item>
          <Carousel.Item>
            <Card className="cardDouble bg-grey7 mx-auto border border-0">
              <Row className="px-1 py-2 m-1">
                <Col xs={12} lg={6} className="m-0 p-0 my-2 bg-grey7">
                  <AvisCard1
                    text="Cherpas m'enlève une épine du pied et s'occupe de résilier et souscrire de nouveaux contrats bien plus intéressants pour mon portefeuille. Equipe réactive en cas de question. A recommander !"
                    name="SARAH P."
                    date="22/06/2022"
                    link="https://www.avis-verifies.com/avis-clients/cherpas.com"
                    stars={5}
                  />
                </Col>
                <Col xs={12} lg={6} className="m-0 p-0 my-2 bg-grey7">
                  <AvisCard2
                    text="Des recommandations de qualité et ils s'occupent de tout pour changer nos contrats. De plus le suivi régulier (alerte prix) est un bon point. Bravo"
                    name="CHARLES D."
                    date="22/06/2022"
                    link="https://www.avis-verifies.com/avis-clients/cherpas.com"
                    stars={5}
                  />
                </Col>
              </Row>
            </Card>
          </Carousel.Item>
        </Carousel>
      </Col>
      <Col xs={12} sm={1} md={1} lg={1} xl={2}></Col>
    </Row>
  );
};

export default Testimonials;
