import { Card, Col, Row } from "react-bootstrap";
import { element, object, string } from "prop-types";
import { faBolt, faMobile, faRouter } from "@fortawesome/pro-light-svg-icons";

import Clickable from "/components/generics/clickable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import t from "../../../lib/cleanFrenchTypographie";

const Services = ({ kabru }) => {
  Services.propTypes = {
    kabru: object,
  };

  const Ca = ({ link, title, text, icon }) => {
    Ca.propTypes = {
      link: string,
      title: string,
      text: string,
      icon: element,
    };
    return (
      <>
        <Col xs={12} md={4} className="my-0 mx-auto p-3 bg-blue2">
          <Card className="bg-blue2 bg-white h-100">
            <Card.Title className="p-3 pt-4 mb-0 fw-bold">
              <p className="d-flex">
                <FontAwesomeIcon
                  icon={icon}
                  size="2x"
                  className="text-secondary"
                />
                &nbsp;&nbsp; &nbsp;
                <span className="my-auto">{title}</span>
              </p>
            </Card.Title>
            <Card.Body className="pb-2 pt-0 px-3">
              <p className="mb-0 fw-bolder">{text}</p>
              <div className="pt-4 pb-2">
                <Link legacyBehavior prefetch={false} href={link}>
                  <Clickable force={1} className="text-cta fw-bold">
                    <span className="fw-bold text-second1 test-center">
                      En savoir plus
                    </span>
                  </Clickable>
                </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  };
  return (
    <>
      <Row className="bg-blue2 not_all m-0 p-0 d-flex">
        <Col xs={12} lg={10} xl={8} className="my-0 all mx-auto">
          <Row className="d-flex flex-wrap py-1">
            <Ca
              link={"/electricite-gaz"}
              text={t(kabru.home.services.card1.text)}
              title={t(kabru.home.services.card1.title)}
              icon={faBolt}
            ></Ca>
            <Ca
              title={t(kabru.home.services.card3.title)}
              text={t(kabru.home.services.card3.text)}
              link={"/internet"}
              icon={faRouter}
            ></Ca>
            <Ca
              title={t(kabru.home.services.card4.title)}
              text={t(kabru.home.services.card4.text)}
              link={"/mobile"}
              icon={faMobile}
            ></Ca>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Services;
